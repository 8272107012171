<template>
  <div v-if="transshipment">
    <div class="c-accordion">
      <input
        :id="`transshipment-${index}`"
        class="c-accordion__cb"
        type="checkbox"
      />
      <div class="c-accordion__head">
        <label
          class="c-accordion__head__lbl"
          :for="`transshipment-${index}`"
        ></label>
        <div class="c-accordion__head__text">{{ transshipment.name }}</div>
      </div>
      <div class="c-accordion__body for-form formList">
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>住所</label>
          </div>
          <div class="c-inputWrap__items">
            〒 {{ transshipment.addressInfo.zipCode }}
            {{ transshipment.addressInfo.prefecturesName }}
            {{ transshipment.addressInfo.address }}
            {{ transshipment.addressInfo.buildingName }}
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>電話番号</label>
          </div>
          <div class="c-inputWrap__items">
            {{ transshipment.tel | blankText }}
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>FAX番号</label>
          </div>
          <div class="c-inputWrap__items">
            {{ transshipment.fax | blankText }}
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>JWNETID(加入者番号)</label>
          </div>
          <div class="c-inputWrap__items">
            {{ transshipment.jwnetContractNo | blankText }}
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>事業場コード(積保)</label>
          </div>
          <div class="c-inputWrap__items">
            {{ transshipment.transshipmentStoreCode | blankText }}
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>面積</label>
          </div>
          <div class="c-inputWrap__items">
            <template v-if="transshipment.area">
              {{ transshipment.area | formatFloat }} m&sup2;
            </template>
            <template v-else>-</template>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>保管上限</label>
          </div>
          <div class="c-inputWrap__items">
            <template v-if="transshipment.storageUpperLimit">
              {{ transshipment.storageUpperLimit | formatFloat }} m&sup3;
            </template>
            <template v-else>-</template>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>積み上げ高さ</label>
          </div>
          <div class="c-inputWrap__items">
            <template v-if="transshipment.height">
              {{ transshipment.height | formatFloat }} m
            </template>
            <template v-else>-</template>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__label">
            <label>積載可能な廃棄物種類</label>
          </div>
          <div class="c-inputWrap__items">
            {{ transshipment.transshipmentJwnetItemList.map(t => t.name).join("、") }}
            <template v-if="!transshipment.transshipmentJwnetItemList.length">-</template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    transshipment: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
};
</script>
<style scoped lang="scss">
.c-accordion {
  margin: 0px 0px 15px 0px;
}
.formList {
  margin: 0px 0px 25px 10px;
}
</style>
