import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      collectLicence: {
        companyInfo: {},
        licenceInfo: {
          municipalityInfo: {},
          targetJwnetItemList: [],
          transshipmentList: [],
        },
      },
      commonWasteCollectLicence: {
        companyInfo: {},
        licenceInfo: {
          targetJwnetItemList: [],
          transshipmentList: [],
        },
      },
      errMsgs: [],
    };
  },
  methods: {
    getLicenseDetailApi(id) {
      return axios
        .get(API_URL.LICENCE.COLLECT_LICENCE + id)
        .then((res) => {
          this.collectLicence = res.data;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          console.log(err);
        });
    },
    getCommonWasteLicenseDetailApi(id) {
      return axios
        .get(API_URL.LICENCE.COMMON_COLLECT_LICENCE + id)
        .then((res) => {
          this.commonWasteCollectLicence = res.data;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          console.log(err);
        });
    },
  },
};
